import React, { useState, useEffect } from 'react';
import { MetadataModel } from '../models/metadataModel';
import { metadataApi } from '../apis/metadata';
import { Editor } from '@progress/kendo-react-editor';
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import { SvgIcon } from '@progress/kendo-react-common';
import { arrowDownIcon, arrowUpIcon } from '@progress/kendo-svg-icons';
import { isMobile } from 'react-device-detect';
import { useLibraryContext } from '../context/LibraryContext';
import { showMessage } from '../services/languages/_showmessages';

interface DocTypeProps {
  library: string;
  folder: number;
  page: number;
}

const DocTypeComponent: React.FC<DocTypeProps> = ({ library, folder, page }) => {
  const [docType, setDocType] = useState<MetadataModel | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [expand, setExpand] = useState<boolean | null>(null);
  const { Library } = useLibraryContext();

  useEffect(() => {
    const fetchData = async () => {
      if (folder === 0 || page === 0) 
        {
          setDocType(null);
          setExpand(null);
          return;
        }
        
        try {
          const data = await metadataApi(library, folder, page);
          setDocType(data);
          setExpand(data?.docType != '' ? true : false);
        } catch (err) {
          setError('Failed to fetch data');
        }
    };

    fetchData();
  }, [folder, page]);

  const toggleExpand = () => {
    setExpand(!expand); 
  };

  const ExpandColapsIcon = () => expand ? <SvgIcon className='closeSvgIcon' icon={arrowDownIcon} /> : <SvgIcon className='closeSvgIcon' icon={arrowUpIcon} />;

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {isMobile ?
        <>
          <div className='docTypePosition-mobile' onClick={toggleExpand}>
            <AppBar className='transparent maxappbar'>
              <AppBarSection>
                <div className='closeButton'>{Library?.docTypeTitle ?? showMessage("doctypetitle")}{ExpandColapsIcon()}</div>
              </AppBarSection>
            </AppBar>

            {expand ? 
            <>
              <Editor contentStyle={{ height: 120, width: '100%' }} value={docType?.docType ?? ''} />
            </>
            : 
            <></>}
          </div>
        </>
        :
        <>
          <div className='docTypePosition-desktop' onClick={toggleExpand}>
            <AppBar className='transparent maxappbar'>
              <AppBarSection>
                <div className='closeButton'>{Library?.docTypeTitle ?? showMessage("doctypetitle")}{ExpandColapsIcon()}</div>
              </AppBarSection>
            </AppBar>
            {expand ? 
            <>
              <Editor contentStyle={{ height: 120, width: '100%' }} value={docType?.docType ?? ''} />
            </>
            : 
            <></>}
          </div>
        </>
      }
    </>
  );
};

export default DocTypeComponent;