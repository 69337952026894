import React, { useContext, ReactNode } from 'react';
import { thumbsModel } from '../models/thumbsModel';

interface ThumbsContextType {  
  selected: thumbsModel[];
  setSelected: React.Dispatch<React.SetStateAction<thumbsModel[]>>; 
}

const ThumbsContext = React.createContext<ThumbsContextType | undefined>(undefined);

interface ThumbsContextProviderProps {
  children: ReactNode;
}

export function ThumbsContextProvider({ children }: ThumbsContextProviderProps) {  
  const [selected, setSelected] = React.useState<thumbsModel[]>([]);
  
  return (
    <ThumbsContext.Provider value={{ selected, setSelected }}>
      {children}
    </ThumbsContext.Provider>
  );
}

export function useThumbsContext() {
  const context = useContext(ThumbsContext);
  if (context === undefined) {
    throw new Error('useThumbsContext must be used within a ThumbsContext');
  }
  return context;
}