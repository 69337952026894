import React, { MutableRefObject, useEffect } from "react";
import { ReactZoomPanPinchContentRef } from "react-zoom-pan-pinch";
import { Window } from "@progress/kendo-react-dialogs";
import useWindowDimensions from './WindowDimensions';
import { useControlContext } from '../context/ControlContext';

interface ZoomProps {
    imgDrawId: string;
    imgUrl: string;
    refs: MutableRefObject<(ReactZoomPanPinchContentRef | null)[]>;
 }

 export const ZoomBox: React.FC<ZoomProps> = ( {imgDrawId, imgUrl, refs}) => {  
    const { WindowWidth } = useWindowDimensions();
    const { commandControlContext, setCommandControlContext } = useControlContext();

    useEffect(() => {
        refs.current[0]?.centerView(1,0);
    }, [imgUrl]);

    const zoomIn = () => {
        refs.current[0]?.zoomIn();
    };

    const zoomOut = () => {
        refs.current[0]?.zoomOut();
    };

    const bestFit = () => {
        refs.current[0]?.centerView(1);
    };

    const fitToWidth = () => {
        const img = document.getElementById(imgDrawId) as HTMLImageElement;
        if (!img) { return; }

        let clientRect = img.getBoundingClientRect();
        let scale = WindowWidth / clientRect.width;

        if (WindowWidth === clientRect.width) {return;}

        refs.current[0]?.setTransform(0,0,scale);
    };  

    const Close = () => {
        setCommandControlContext({isZoomVisible: false});
    };

    const showZoom = () => {        
        return commandControlContext?.isZoomVisible;
    }
    
    return (
        <>
            {showZoom() ?
            <Window title={"Zoom"} onClose={Close} resizable={false} initialWidth={200} initialHeight={80} initialLeft={WindowWidth-250} initialTop={90}
                    minimizeButton={()=> null} maximizeButton={()=> null} >
                <div className="center">
                    <img onClick={() => zoomIn()} className='imgZoom' alt='Zoom in' src={"/images/01ZoomIn.png"} />
                    <img onClick={() => zoomOut()} className='imgZoom' alt='Zoom out' src={"/images/02ZoomOut.png"} />
                    <img onClick={() => bestFit()} className='imgZoom' alt='Best fit' src={"/images/05ZoomBestFit.png"} />
                    <img onClick={() => fitToWidth()} className='imgZoom' alt='Zoom Width' src={"/images/06ZoomWidth.png"} />
                    {/* <img onClick={() => fit100Percent()} className='imgZoom' src={"/images/08Zoom100.png"} /> */}
                </div>
            </Window>
            : <> </> }
        </>
    )
}