import React from 'react';

interface RedirProps {
  library: any;
}

const RedirectComponent: React.FC<RedirProps> = ({ library }) => {
  const showBody = () => {
    const appBody = document.getElementById("appBody");
    if (appBody) {
      appBody.style.display = "block";
    }
  };

  showBody();

  return <></>;
};

export default RedirectComponent;