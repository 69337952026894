import React, { useContext, ReactNode } from 'react';

// Definindo o tipo para o contexto
interface MessageBoxContextType {
  messageBox: string | null;
  setMessageBox: React.Dispatch<React.SetStateAction<string | null>>;
}

// 1. Crie um novo contexto com tipo definido
const MessageBoxContext = React.createContext<MessageBoxContextType | undefined>(undefined);

// Definindo o tipo para as props do provedor
interface MessageBoxContextProviderProps {
  children: ReactNode;
}

// 2. Crie um provedor de contexto
export function MessageBoxContextProvider({ children }: MessageBoxContextProviderProps) {  
  const [messageBox, setMessageBox] = React.useState<string | null>(null);

  return (
    <MessageBoxContext.Provider value={{ messageBox, setMessageBox }}>
      {children}
    </MessageBoxContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useMessageBoxContext(): MessageBoxContextType {
  const context = useContext(MessageBoxContext);
  if (context === undefined) {
    throw new Error('useMessageBoxContext must be used within a MessageBoxContextProvider');
  }
  return context;
}