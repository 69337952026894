import React, { useContext, ReactNode } from 'react';
import { LibrariesListModel } from "../models/librariesModel";

// Definindo o tipo para o contexto
interface LibrariesContextType {
  Libraries: LibrariesListModel[] | null;
  setLibraries: React.Dispatch<React.SetStateAction<LibrariesListModel[] | null>>;
}

// 1. Crie um novo contexto com tipo definido
const LibrariesContext = React.createContext<LibrariesContextType | undefined>(undefined);

// Definindo o tipo para as props do provedor
interface LibrariesContextProviderProps {
  children: ReactNode;
}

// 2. Crie um provedor de contexto
export function LibrariesContextProvider({ children }: LibrariesContextProviderProps) {  
  const [Libraries, setLibraries] = React.useState<LibrariesListModel[] | null>(null);

  return (
    <LibrariesContext.Provider value={{ Libraries, setLibraries }}>
      {children}
    </LibrariesContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useLibrariesContext(): LibrariesContextType {
  const context = useContext(LibrariesContext);
  if (context === undefined) {
    throw new Error('useLibraries must be used within a LibrariesContextProvider');
  }
  return context;
}