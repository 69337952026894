import React, { useEffect, useState } from 'react';
import { buildHashThumb, getThumb } from '../../apis/thumbs';
import { ImageModel } from '../../models/imageModel';
import { usePageContext } from '../../context/PageContext';
import { usePageLoadContext } from '../../context/PageLoadContext';
import { findFolderById } from '../../services/shared';
import { useFoldersContext } from '../../context/FoldersContext';
import { FoldersModel } from '../../models/foldersModel';
import { useFolderContext } from '../../context/FolderContext';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useThumbsContext } from '../../context/ThumbsContext';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'react-tooltip';

interface ThumbsProp {
  key: string,
  library: string,
  folderId: number,
  page: number,
  cachedImages: ImageModel[];
  setCachedImages: React.Dispatch<React.SetStateAction<ImageModel[]>>;
  height: number;
  tooltip: string;
}

const Thumbs: React.FC<ThumbsProp> = ({ library, folderId, page, cachedImages, height, setCachedImages, tooltip }) => {
  const [image, setImage] = React.useState<ImageModel | null>(null);
  const [hasImage, setHasImage] = useState<boolean>(false);
  const [styleH] = useState<{ height: string }>({ height: (10 + height) + 'px' });
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();
  const { Folders } = useFoldersContext();
  const { setFolder } = useFolderContext();
  const [thumbChecked, setThumbChecked] = useState<boolean>(false);
  const { selected, setSelected } = useThumbsContext();

  useEffect(() => {
      const fetchThumbContent = async () => {
        const cachedImage = cachedImages.find(h => h.hash === hash);

        if (cachedImage) {
          setHasImage(true);
          setImage(cachedImage);
          return;
        }
        const thumb = await getThumb(hash, library, folderId, page, height);

        if (thumb) {
          setImage(thumb);
          setCachedImages(cachedImages => [...cachedImages, thumb]);
          setHasImage(thumb !== null);
        }
      };

      const key = 'thumb';
      const hash = buildHashThumb(key, library, folderId, page, height);

      if (hash) {
        fetchThumbContent();
      }
  }, []);

  const clickPage = () => {
    let folder: FoldersModel | null = findFolderById(Folders, folderId);
    if (folder === null) {
      alert('ERROR: Folder not found!');
      return;
    }

    setFolder(folder);
    setPage(page);

    const PTime2Load = 500;
    setTimeout(() => {
      setPageLoad(true);
    }, PTime2Load);
  };

  const checkUnCheckItem = () => {
    let checked = !thumbChecked;

    setSelected((prevMarcadas) => {
      const newMarcadas = prevMarcadas.filter(m => !(m.page == page && m.folderId == folderId));
      const listaMarcados = [...newMarcadas, { folderId: folderId, page: page, checked: checked }];
      return listaMarcados;
    });

    setThumbChecked(checked);
  };

  useEffect(() => {
    var item = selected.find(m => m.page === page && m.folderId === folderId && m.checked === true);
    if (item) {
      setThumbChecked(true);
    }
  }, []);

  return (
    <>
      {!hasImage ? 
        <></> 
      :
        <div className="k-listview-item">
          <div className='thumbItem' style={styleH}>
            <div className='imgThumbDrawContainer' >
              <img onClick={clickPage} src={image?.base64 || undefined} className='imgThumbDraw' alt={'Page' + page} data-tooltip-content={tooltip} data-tooltip-id={'Page' + page} />
              {/* <Tooltip id={'Page' + page} place="top" float={false} /> */}
            </div>
            <div className='thumbFooter'>
              <div className='thumbCheck'>{!isMobile ? <Checkbox className='checkBoxThumb' checked={thumbChecked} onClick={() => checkUnCheckItem()} /> : <></>}</div>
              <div className='thumbTitle'>{page}</div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Thumbs;