import React, { useContext, ReactNode } from 'react';
import { LibraryModel } from '../models/librariesModel';

// Definindo o tipo para o contexto
interface LibraryContextType {
  Library: LibraryModel | null;
  setLibrary: React.Dispatch<React.SetStateAction<LibraryModel | null>>;
}

// 1. Crie um novo contexto com tipo definido
const LibraryContext = React.createContext<LibraryContextType | undefined>(undefined);

// Definindo o tipo para as props do provedor
interface LibraryContextProviderProps {
  children: ReactNode;
}

// 2. Crie um provedor de contexto
export function LibraryContextProvider({ children }: LibraryContextProviderProps) {  
  const [Library, setLibrary] = React.useState<LibraryModel | null>(null);

  return (
    <LibraryContext.Provider value={{ Library, setLibrary }}>
      {children}
    </LibraryContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useLibraryContext(): LibraryContextType {
  const context = useContext(LibraryContext);
  if (context === undefined) {
    throw new Error('useLibrary must be used within a LibraryContextProvider');
  }
  return context;
}