import axios from 'axios';
import { BaseModel } from '../models/baseModel';
import { FoldersModel } from '../models/foldersModel';
import { getDocReaderAPI, getDefaultBiblioteca, getShowLogs } from '../services/appSettings';

const baseUrl = getDocReaderAPI();

export const foldersApi = async (library: string) : Promise<FoldersModel[]> => {
  if (!baseUrl) {
    console.error('Environment variable REACT_APP_DOCREADERAPI is not set');
    throw new Error("DocReaderAPI Url not set");
  } 

  const url = `${baseUrl}/folders/${(library ?? (getDefaultBiblioteca() ?? ''))}`;  
  
  var result: Promise<FoldersModel[]> = axios.get(url)
    .then(response => {
      var basedata = response.data as BaseModel;                  
      if (basedata.sucesso) {
        if (getShowLogs() === 'on') {console.log(basedata.mensagem);}
        var data = JSON.parse(basedata.data) as FoldersModel[];
        return data;
      }
      console.error(basedata.mensagem);
      throw new Error(basedata.mensagem);
    })
    .catch(error => {
      console.error(error);
      throw new Error(error.response.status);
    });
  return result;
};