import React, { useContext, ReactNode } from 'react';

// 1. Crie um novo contexto
interface ThumbsLoadContextProps {
  ThumbsLoad: boolean;
  setThumbsLoad: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThumbsLoadContext = React.createContext<ThumbsLoadContextProps | undefined>(undefined);

// 2. Crie um provedor de contexto
interface ThumbsLoadContextProviderProps {
  children: ReactNode;
}

export function ThumbsLoadContextProvider({ children }: ThumbsLoadContextProviderProps) {
  const [ThumbsLoad, setThumbsLoad] = React.useState<boolean>(false);

  return (
    <ThumbsLoadContext.Provider value={{ ThumbsLoad, setThumbsLoad}}>
      {children}
    </ThumbsLoadContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useThumbsLoadContext() {
  const context = useContext(ThumbsLoadContext);
  if (context === undefined) {
    throw new Error('useThumbsLoad must be used within a ThumbsLoadContextProvider');
  }
  return context;
}