import React, { useContext, ReactNode } from 'react';
import { SearchModel } from '../models/searchModel';

// Defining the type for the context
interface SearchContextType {
  Search: SearchModel | null;
  setSearch: React.Dispatch<React.SetStateAction<SearchModel | null>>;
}

// 1. Create a new context with defined type
const SearchContext = React.createContext<SearchContextType | undefined>(undefined);

// Defining the type for the provider props
interface SearchContextProviderProps {
  children: ReactNode;
}

// 2. Create a context provider
export function SearchContextProvider({ children }: SearchContextProviderProps) {  
  const [Search, setSearch] = React.useState<SearchModel | null>(null);

  return (
    <SearchContext.Provider value={{ Search, setSearch }}>
      {children}
    </SearchContext.Provider>
  );
}

// 3. Create a custom hook to use the context
export function useSearchContext(): SearchContextType {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchContextProvider');
  }
  return context;
}