import React from 'react';
import { showMessage } from '../services/languages/_showmessages';

const ErrorMessage = (props: any) => {
  return (
    <>
      <div className='error'>
        {showMessage("librarynotfound")}
      </div>
    </>
  );
}

export default ErrorMessage;