import React, { useContext, ReactNode } from 'react';

// Definindo o tipo para o contexto
interface LoadingContextType {
  Loading: boolean | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean | null>>;
}

// 1. Crie um novo contexto com tipo definido
const LoadingContext = React.createContext<LoadingContextType | undefined>(undefined);

// Definindo o tipo para as props do provedor
interface LoadingContextProviderProps {
  children: ReactNode;
}

// 2. Crie um provedor de contexto
export function LoadingContextProvider({ children }: LoadingContextProviderProps) {  
  const [Loading, setLoading] = React.useState<boolean | null>(null);

  return (
    <LoadingContext.Provider value={{ Loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useLoadingContext(): LoadingContextType {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoadingContext must be used within a LoadingContextProvider');
  }
  return context;
}