import React, { useContext, ReactNode } from 'react';
import { FoldersModel } from '../models/foldersModel';

// Definindo o tipo para o contexto
interface FoldersContextType {
  Folders: FoldersModel[] | null;
  setFolders: React.Dispatch<React.SetStateAction<FoldersModel[] | null>>;
}

// 1. Crie um novo contexto com tipo definido
const FoldersContext = React.createContext<FoldersContextType | undefined>(undefined);

// Definindo o tipo para as props do provedor
interface FoldersContextProviderProps {
  children: ReactNode;
}

// 2. Crie um provedor de contexto
export function FoldersContextProvider({ children }: FoldersContextProviderProps) {  
  const [Folders, setFolders] = React.useState<FoldersModel[] | null>(null);

  return (
    <FoldersContext.Provider value={{ Folders, setFolders }}>
      {children}
    </FoldersContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useFoldersContext(): FoldersContextType {
  const context = useContext(FoldersContext);
  if (context === undefined) {
    throw new Error('useFolders must be used within a FoldersContextProvider');
  }
  return context;
}