import React, { useContext, ReactNode } from 'react';
import { FoldersModel } from '../models/foldersModel';

// Definindo o tipo para o contexto
interface FolderContextType {
  Folder: FoldersModel | null;
  setFolder: React.Dispatch<React.SetStateAction<FoldersModel | null>>;
}

// 1. Crie um novo contexto com tipo definido
const FolderContext = React.createContext<FolderContextType | undefined>(undefined);

// Definindo o tipo para as props do provedor
interface FolderContextProviderProps {
  children: ReactNode;
}

// 2. Crie um provedor de contexto
export function FolderContextProvider({ children }: FolderContextProviderProps) {  
  const [Folder, setFolder] = React.useState<FoldersModel | null>(null);

  return (
    <FolderContext.Provider value={{ Folder, setFolder }}>
      {children}
    </FolderContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useFolderContext(): FolderContextType {
  const context = useContext(FolderContext);
  if (context === undefined) {
    throw new Error('useFolder must be used within a FolderContextProvider');
  }
  return context;
}