import React, { useContext, ReactNode } from 'react';

// Definindo o tipo para o contexto
interface SearchTextContextType {
  SearchText: string | null;
  setSearchText: React.Dispatch<React.SetStateAction<string | null>>;
}

// 1. Crie um novo contexto com tipo definido
const SearchTextContext = React.createContext<SearchTextContextType | undefined>(undefined);

// Definindo o tipo para as props do provedor
interface SearchTextProviderProps {
  children: ReactNode;
}

// 2. Crie um provedor de contexto
export function SearchTextContextProvider({ children }: SearchTextProviderProps) {  
  const [SearchText, setSearchText] = React.useState<string | null>(null);

  return (
    <SearchTextContext.Provider value={{ SearchText, setSearchText }}>
      {children}
    </SearchTextContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useSearchTextContext(): SearchTextContextType {
  const context = useContext(SearchTextContext);
  if (context === undefined) {
    throw new Error('useSearchTextContext must be used within a SearchTextContextProvider');
  }
  return context;
}