export const fetchSettingsSync = () => {
  const defaultResult = {
    "REACT_APP_DOCREADERAPI": "https://api.docvirt.com/v1",
    "REACT_APP_DOCSEARCHAPI": "https://search.docvirt.com/v1",
    "REACT_APP_INITITAL_HEIGHT_THUMBS": "100",
    "REACT_APP_SHOW_LOGS": "off",
    "REACT_APP_DEFAULT_BIBLIOTECA": "demo",
  };

    try {
    const request = new XMLHttpRequest();
    request.open('GET', `${process.env.PUBLIC_URL}/${process.env.REACT_APP_API_FILE_CONFIG}`, false); // false makes the request synchronous
    request.send(null);

    if (request.status === 200) {
      return JSON.parse(request.responseText);
    } else {
      return defaultResult;
    }
  } catch (e) {
    return defaultResult;
  }
}

const settings = fetchSettingsSync();

export const getDocReaderAPI = (): string => settings.REACT_APP_DOCREADERAPI;
export const getDocSearchAPI = (): string => settings.REACT_APP_DOCSEARCHAPI;
export const getShowLogs = (): string => settings.REACT_APP_SHOW_LOGS;
export const getDefaultBiblioteca = (): string => settings.REACT_APP_DEFAULT_BIBLIOTECA;
export const getInitialHeightThumbs = (): number => Number(settings.REACT_APP_INITITAL_HEIGHT_THUMBS);