import React, { ReactElement, useEffect } from 'react';
import { libraryApi } from '../apis/library';
import { useLibrariesContext } from '../context/LibrariesContext';
import { useLibraryContext } from '../context/LibraryContext';
import { useParams } from 'react-router-dom';
import { DocReader } from '../pages/docreader';
import RedirectComponent from '../components/RedirectComponent';
import ErrorMessage from '../components/ErrorMessage';
import { Loader } from '@progress/kendo-react-indicators';
import { DocMulti } from '../pages/docmulti';
import { useSearchTextContext } from '../context/SearchTextContext';
import { useFoldersContext } from '../context/FoldersContext';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import { isMobile } from 'react-device-detect';
import { findFolderByName, findFolderWithPage } from '../services/shared';
import { getDefaultBiblioteca } from '../services/appSettings';

type RouteParams = {
  library: string;
  searchText?: string;
  folder?: string;
  page?: string;
  pagfis?: string;
};

export const BaseDocPro = (): ReactElement | null => {
  const { library, searchText, folder, page, pagfis } = useParams<RouteParams>();
  const [ cDefaultBiblioteca, setCDefaultBiblioteca ] = React.useState<string>(library !== undefined ? library : (getDefaultBiblioteca() + ''));
  const { Libraries } = useLibrariesContext();
  const { Library, setLibrary } = useLibraryContext();
  const [ loading, setLoading ] = React.useState<boolean>(Library?.type === undefined || cDefaultBiblioteca === 'demo');     
  const { Folders } = useFoldersContext();
  const { setSearchText } = useSearchTextContext();
  const [ error, setError ] = React.useState<boolean>(false);
  const { setFolder } = useFolderContext();
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();
  
  useEffect(() => {
    if (cDefaultBiblioteca === '') {
      setCDefaultBiblioteca(getDefaultBiblioteca() + '');
    }

    if (library !== cDefaultBiblioteca) {
      if (library !== '') { setCDefaultBiblioteca(library!); }

      setLoading(true);
    }
  }, [library]);

  useEffect(() => {
    if (Library === null) {
      const loadingX = loading;
      if (loadingX) {
        const bib = cDefaultBiblioteca !== '' ? cDefaultBiblioteca : getDefaultBiblioteca() + '';
        
        const fetchData = async () => {
          try {
            const result = await libraryApi(bib ?? '');
            setCDefaultBiblioteca(cDefaultBiblioteca);
            setLibrary(result);
            document.title = (result?.description === "") ? result?.name + " - " : document.title = result?.description + " - ";
            document.title +=  "DocReader Web";  
          }
          catch (error1) {
            console.debug(error1);
            setError(true);
          };
        };

        fetchData();
      }
    }
  }, [loading, Library]);
  
  useEffect(() => {
    if (Folders === null || Folders === undefined) return;

    // Se entrou com pesquisa na URL, faz a pesquisa
    if (searchText !== null && searchText?.trim() !== '' && searchText !== undefined) {
      setSearchText(searchText);
      return;
    }
    
    // Se entrou com uma pasta na URL procura a pasta
    let foldertmp = findFolderByName(Folders, folder?.trim() || '');
    let pagetmp = parseInt(page ?? "1");

    if (foldertmp !== null) {
      if (pagetmp < 0) pagetmp = 1
      if (pagetmp > foldertmp.Pages) pagetmp = foldertmp.Pages
    }
    else {
      foldertmp = findFolderWithPage(Folders) ?? Folders[0];
    }

    setFolder(foldertmp); 
    setPage((foldertmp.Pages === 0 ? 0 : pagetmp));
    
    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
    setTimeout(() => {
      setPageLoad(true);
    }, 100);
  }, [Folders]);

  useEffect(() => {
    if (Libraries === null || Libraries === undefined) return;

    if (searchText !== null && searchText?.trim() !== '' && searchText !== undefined) {
      // Se entrou com pesquisa na URL
      setSearchText(searchText);
    }
  }, [Libraries]);

  return (
    <>
      <RedirectComponent library={cDefaultBiblioteca} />

      {Library === null ? 
        <>
            {error ?
              <ErrorMessage />
            :
              <span className={isMobile ? 'loader-mobile' : 'loader'}>
                <Loader type='converging-spinner' />
              </span>
            }
        </>
    :
        <>
          {Library.type === 1 ?
            <DocMulti library={cDefaultBiblioteca} />
          :
            <DocReader library={cDefaultBiblioteca} />
          }
        </>
      }
    </>
  );
};