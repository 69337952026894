
import React, { useContext, ReactNode } from 'react';

// 1. Crie um novo contexto
interface PageContextProps {
  Page: number | null;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const PageContext = React.createContext<PageContextProps | undefined>(undefined);

// 2. Crie um provedor de contexto
interface PageContextProviderProps {
  children: ReactNode;
}

export function PageContextProvider({ children }: PageContextProviderProps) {
  const [Page, setPage] = React.useState<number>(0);

  return (
    <PageContext.Provider value={{ Page, setPage}}>
      {children}
    </PageContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function usePageContext() {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error('usePage must be used within a PageContextProvider');
  }
  return context;
}