export const ptMessages = {
  acervos: "Acervos",
  alias: "Alias",
  ate: "até",
  capas: "Capas",
  cliqueParaPesquisar: "Clique para pesquisar",
  compartilharNo: "Compartilhar no",
  descricao: "Descrição",
  digiteTextoParaPesquisar: "Digite um texto para pesquisar...",
  doctypetitle: "Metadados",
  download: "Download",
  downloads: "Downloads",
  exibir: "Exibir",
  exportdisablemsg: "Exportação desativada",
  fechar: "Fechar",
  fecharMenu: "Fechar menu",
  filtrar: "Filtrar",
  filtrarNomesDeBibliotecas: "Filtrar nomes de bibliotecas...",
  gerar: "Gerar",
  incluirSubPastas: "Incluir subpastas",
  incluirSubpastas: "Incluir subpastas",
  info: "Info",
  informacoes: "Informações",
  librarynotfound: "Biblioteca não encontrada",
  limpaaPesquisa: "Limpa a pesquisa",
  miniaturas: "Miniaturas",
  miniaturasCapas: "Miniaturas das capas",
  miniaturasMarcadas: "Miniaturas marcadas",
  miniaturasOcorrencias: "Miniaturas das ocorrências",
  naoExistePaginas: "Não existem páginas para gerar um PDF",
  nome: "Nome",
  ocorrencias: "Ocorrências",
  ocultar: "Ocultar",
  pagina: "Página",
  paginas: "Páginas",	
  paginasDe: "Páginas de",
  pastas: "Pastas",
  pesquisarNasBibliotecas: "Pesquisar nas bibliotecas...",
  pesquisar: "Pesquisar",
  pressioneEnterParaPesquisar: "Pressione ENTER para pesquisar",
  qualidade: "Qualidade",
  semOcorrencias: "Sem ocorrências",
  todaAPastaAtual: "Toda a pasta atual",
  todasOcorrencias: "Todas as ocorrências",
  todasOcorrenciasPastaAtual: "Ocorrências da pasta atual",
  totalDePaginas: "Total de páginas",
  totalDePastas: "Total de pastas",
  zoom: "Zoom",
};