import React, { useContext, ReactNode } from 'react';
import { thumbsWindowRectModel } from '../models/thumbsWindowRectModel';

interface ThumbsWindowRectContextType {  
  ThumbsWindowRect: thumbsWindowRectModel | null;
  setThumbsWindowRect: React.Dispatch<React.SetStateAction<thumbsWindowRectModel | null>>;
}

const ThumbsWindowRectContext = React.createContext<ThumbsWindowRectContextType | undefined>(undefined);

interface ThumbsWindowRectContextProviderProps {
  children: ReactNode;
}

export function ThumbsWindowRectContextProvider({ children }: ThumbsWindowRectContextProviderProps) {  
  const [ThumbsWindowRect, setThumbsWindowRect] = React.useState<thumbsWindowRectModel | null>(null);
  
  return (
    <ThumbsWindowRectContext.Provider value={{ ThumbsWindowRect, setThumbsWindowRect }}>
      {children}
    </ThumbsWindowRectContext.Provider>
  );
}

export function useThumbsWindowRectContext() {
  const context = useContext(ThumbsWindowRectContext);
  if (context === undefined) {
    throw new Error('useThumbsContext must be used within a ThumbsContext');
  }
  return context;
}