import React, { useContext, ReactNode } from 'react';

// 1. Crie um novo contexto
interface PageLoadContextProps {
  PageLoad: boolean | null;
  setPageLoad: React.Dispatch<React.SetStateAction<boolean>>;
}

const PageLoadContext = React.createContext<PageLoadContextProps | undefined>(undefined);

// 2. Crie um provedor de contexto
interface PageLoadContextProviderProps {
  children: ReactNode;
}

export function PageLoadContextProvider({ children }: PageLoadContextProviderProps) {
  const [PageLoad, setPageLoad] = React.useState<boolean>(false);

  return (
    <PageLoadContext.Provider value={{ PageLoad, setPageLoad}}>
      {children}
    </PageLoadContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function usePageLoadContext() {
  const context = useContext(PageLoadContext);
  if (context === undefined) {
    throw new Error('usePageLoad must be used within a PageLoadContextProvider');
  }
  return context;
}