
import React, { useContext, ReactNode } from 'react';
import { ControlDataModel } from '../models/controlDataModel';

// Defining the type for the context
interface ControlContextType {
  commandControlContext: ControlDataModel | null;
  setCommandControlContext: React.Dispatch<React.SetStateAction<ControlDataModel | null>>;
}

// 1. Create a new context with defined type
const ControlContext = React.createContext<ControlContextType | undefined>(undefined);

// Defining the type for the provider props
interface ControlContextProviderProps {
  children: ReactNode;
}

// 2. Create a context provider
export function ControlContextProvider({ children }: ControlContextProviderProps) {  
  const [commandControlContext, setCommandControlContext] = React.useState<ControlDataModel | null>(null);
  
  return (
    <ControlContext.Provider value={{ commandControlContext, setCommandControlContext }}>
      {children}
    </ControlContext.Provider>
  );
}

// 3. Create a custom hook to use the context
export function useControlContext(): ControlContextType {
  const context = useContext(ControlContext);
  if (context === undefined) {
    throw new Error('useControlContext must be used within a ControlContextProvider');
  }
  return context;
}