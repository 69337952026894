import axios from 'axios';
import { BaseModel } from '../models/baseModel';
import { MetadataModel } from '../models/metadataModel';
import { getDocReaderAPI, getShowLogs } from '../services/appSettings';

const baseUrl = getDocReaderAPI();

export const metadataApi = async (library: string, folder: number, page: number): Promise<MetadataModel | null> => {  
  if (!baseUrl) {
    console.error('Environment variable REACT_APP_DOCREADERAPI is not set');
    throw new Error("DocReaderAPI Url not set");
  }

  const url = `${baseUrl}/metadata/${library}/${folder}/${page}`;

  var result: Promise<MetadataModel> = axios.get(url)
    .then(response => {
      var basedata = response.data as BaseModel;
      if (basedata.sucesso) {
        if (getShowLogs() === 'on') {console.log(basedata.mensagem);}
        var data = basedata.data as MetadataModel;
        return data;
      }
      console.error(basedata.mensagem);
      throw new Error(basedata.mensagem);
    })
    .catch(error => {
      console.error(error);
      throw new Error(error.response.status);
    });
    return result;
};